import { graphql, useStaticQuery } from 'gatsby';

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          excerpt
          frontmatter {
            title
            slug
            author
            image {
              childImageSharp {
                fluid(
                  maxWidth: 100
                  maxHeight: 100
                  duotone: { shadow: "#663399", highlight: "#ddbbff" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.allMdx.nodes.map(
    ({
      excerpt,
      frontmatter: {
        title,
        slug,
        author,
        image: {
          childImageSharp: { fluid },
        },
      },
    }) => ({
      title,
      slug,
      author,
      excerpt,
      fluid,
    }),
  );
};

export default usePosts;
